import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion'; // Import necessary hooks
import LeftSide from './LeftSide';
import RightSide from './RightSide';
import './About.css'; // Make sure the CSS file is imported
import { FaArrowDown } from 'react-icons/fa'; // Import the down arrow icon

const About = () => {
  // Hook to track the scroll position
  const { scrollYProgress } = useScroll();  // Gives you the scroll progress from top (0) to bottom (1)
  // Transform scroll position to a scale value for the left and right sides
  const scaleLeft = useTransform(scrollYProgress, [0, 1], [1, 1.5]);  // Scale from 1 to 1.5 as you scroll
  const scaleRight = useTransform(scrollYProgress, [0, 1], [1, 1.5]); // Same for the right side

  return (
    <div className="about-container">
              <h3>
    Hey! I'm Darien      
    </h3>
    <br/>
        <h5>
            I love building, working with interesting, talented people, and above all learning.
        </h5>
        <motion.div
        className="down-arrow-container"
        animate={{ y: [0, 10, 0] }} // Add up-down bounce animation
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatType: 'loop',
        }}
      >      
      <div className="down-arrow-container">
      <FaArrowDown size={30} color="white" /> {/* Adjust the size and color as needed */}

      </div>

        
      </motion.div>

    </div>
  );
};

export default About;
