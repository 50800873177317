import React from 'react'
import './RightSide.css'
const RightSide = () => {
  return (
    <div className="rightDescription">
      <br/>
                <h4>
        DESIGNER

        </h4>
        <h4>
            PROGRAMMER
        </h4>
        <h4>
            BUILDER
        </h4>
        <div>

        </div>
    </div>
  )
}

export default RightSide