import React from 'react'
import { FaArrowDown } from 'react-icons/fa'
import { motion } from "framer-motion"
import './Tech.css'
const Tech = () => {
  return (
    <div className="tech-container">
        <h5>
            I started coding to make my accounting job easier, and fell in love with programming. 
        </h5>
       <br/>
       <h5>
        I've been coding for almost three years and currently work at a startup in Toronto called Stagekeep, helping bring a new AI product to market.
       </h5>

        <br/>
        <h5>
          In my spare time I enjoy learning new things, writing and playing golf. 
        </h5>
        <br/>
        <h5>
        Checkout my blog below.
        </h5>
        <br/>
        <a href="http://blog.darien-c.com" target="_blank" rel="noopener noreferrer">
        <button className="download-button">Visit Blog</button>
      </a>
        
 
      <motion.div
        className="down-arrow-container"
        animate={{ y: [0, 10, 0] }} // Add up-down bounce animation
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatType: 'loop',
        }}
      >      
      <div className="down-arrow-container">
      <FaArrowDown size={30} color="black" />

      </div>

        
      </motion.div>

    </div>
  )
}

export default Tech