import React from 'react';
import { motion } from 'framer-motion';
import './ProjectMain.css'
import { FaArrowDown } from 'react-icons/fa';
const ProjectMain = () => {

  return (
    <div className="project-container">
    
      <h5>
      I've made a bunch of projects over the last few years that built the foundation of my coding knowledge.
      </h5>

        <br/>

        
        <h5>
        Each project has a brief description. Click on the images to see them in action. 
        Scroll below to check them out.
        </h5>


        
        <motion.div
        className="down-arrow-container"
        animate={{ y: [0, 10, 0] }} // Add up-down bounce animation
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatType: 'loop',
        }}
      >      
      <div className="down-arrow-container">
      <FaArrowDown size={30} color="black" /> {/* Adjust the size and color as needed */}

      </div>

        
      </motion.div>

       {/* <div className="LogoContainer">
       <img src={HeaterMainPath.src} alt="Heater Logo"/>
       <img src={timlMainPath.src} alt="timl Logo"/>
       <img src={meandmeMainPath.src} alt="meandme Logo"/>
       </div> */}
        


    </div>
  );
};

export default ProjectMain;