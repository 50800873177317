import React, {useState, useEffect} from 'react';
import './Contact.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import { useRef } from 'react';
import { FaComment } from 'react-icons/fa';
const Contact = ( { count }, color) => {
  const contactImage = "/contact.png";
  const [showContact, setShowContact] = useState(false)
  const form = useRef();


  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    message: ""
  });

  const handleFormData = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const submitForm = () => {
    emailjs.sendForm('service_rh1jpo5', 'template_judcrbb', form.current, 'PiPxWvpqd08fMVgdX')
      .then((result) => {
        setShowContact(false)
        setFormData({
          user_name: "",
          user_email: "",
          message: ""
        });

        toast.success("Email Sent Successfully", { theme: "light" });
      }, (error) => {
        console.log(error.text);
        toast.error("Something Went Wrong", { theme: "light" });
      });
  };
  return (
    <div>
        {showContact ? (
                    <Modal show={showContact} onHide={()=>{setShowContact(false)}} centered>
                    <Modal.Header>
          <Modal.Title>Let's Connect!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => e.preventDefault()} ref={form}>
            <div className="mb-3">

              <input type="text" className="form-control" placeholder="Name" id="user_name" name="user_name" value={formData.user_name} onChange={handleFormData} />
            </div>

            <div className="mb-3">

              <input type="email" className="form-control" id="user_email" placeholder="Email" name="user_email" value={formData.user_email} onChange={handleFormData} />
            </div>

            <div className="mb-3">

              <textarea className="form-control" id="message" placeholder="Message" name="message" rows="4" value={formData.message} onChange={handleFormData} />
            </div>

            <button type="button" className="btn btn-dark" onClick={submitForm}>
              Submit
            </button>
          </form>
        </Modal.Body>
                  </Modal>
        ): (
            <div className="contact-image-container">
            <a onClick={()=>{setShowContact(true)}}>
            <FaComment color={color} className="contactIcon"/>          
            </a>
        </div>

        )}
    </div>

  );
};

export default Contact;
