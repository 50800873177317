import React from 'react'
import { useState, useEffect } from 'react';
import { FaArrowDown } from 'react-icons/fa'
import './TeflonMain.css'
import { motion } from 'framer-motion';
 
const TeflonMain = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const checkMobile = () => {
        setIsMobile(window.matchMedia('(max-width: 528px)').matches);
      };
  
      checkMobile(); // Run the check on component mount
      window.addEventListener('resize', checkMobile); // Update on resize
  
      return () => {
        window.removeEventListener('resize', checkMobile);
      };
    }, []);
  
    const teflonLogo = isMobile
      ? { src: '/TeflonPictures/TeflonBrainMobile.png' }
      : { src: '/TeflonPictures/TeflonBrainMain.png' };

  return (
    <div>
    <div className="LogoContainer">
    <a href="http://teflon.darien-c.com" target="_blank" rel="noopener noreferrer">


        <img src={teflonLogo.src} alt="timl Logo"/>
        </a>

    </div>
    <motion.div
        className="down-arrow-container"
        animate={{ y: [0, 10, 0] }} // Add up-down bounce animation
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatType: 'loop',
        }}
      >      
      <div className="down-arrow-container">
      <FaArrowDown size={30} color="brown" />

      </div>

        
      </motion.div>
    </div>
  )
}

export default TeflonMain