import React from 'react';
import { motion } from 'framer-motion';
import { FaArrowDown } from 'react-icons/fa'; // Import the down arrow icon
import LeftSide from './LeftSide';
import RightSide from './RightSide';
import './Home.css'; // Make sure to include the CSS file

const Home = () => {
  return (
    <div className="home-container">
      <motion.div
        className="left-side"
        initial={{ opacity: 0, x: -200 }}  // Starting state (hidden and slightly off-screen)
        animate={{ opacity: 1, x: 0 }}     // End state (fully visible and in place)
        transition={{ duration: 1.5 }}      // Transition time
        whileInView={{ opacity: 1, x: 0 }} // When the element is in view, animate to the final state
        viewport={{ once: false, amount: 0.3 }} // Start animation when 30% of the element is in view
      >
        <LeftSide />
      </motion.div>

      <motion.div
        className="right-side"
        initial={{ opacity: 0, x: 200 }}  // Start off-screen to the right
        animate={{ opacity: 1, x: 0 }}    // End state (fully visible and in place)
        transition={{ duration: 1.5 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: false, amount: 0.3 }}
      >
        <RightSide />
      </motion.div>

      {/* Down Arrow */}
      <motion.div
        className="down-arrow-container"
        animate={{ y: [0, 10, 0] }} // Add up-down bounce animation
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatType: 'loop',
        }}
      >      
      <div className="down-arrow-container">
      <FaArrowDown size={30} color="black" /> {/* Adjust the size and color as needed */}

      </div>

        
      </motion.div>
    </div>
  );
};

export default Home;
