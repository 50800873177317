import React from 'react'
import { FaArrowDown } from 'react-icons/fa'
import './timl.css'
import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';
const Timl = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 528px)').matches);
    };

    checkMobile(); // Run the check on component mount
    window.addEventListener('resize', checkMobile); // Update on resize

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const timlMainPath = isMobile
    ? { src: '/timlPictures/TIMLMobile.png' }
    : { src: '/timlPictures/timlCropFull.png' };
    

  return (
    
    <div className="LogoContainer">
    <a href="http://timl.darien-c.com" target="_blank" rel="noopener noreferrer">


        <img src={timlMainPath.src} alt="timl Logo"/>
        </a>

    
    <motion.div
        className="down-arrow-container"
        animate={{ y: [0, 10, 0] }} // Add up-down bounce animation
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatType: 'loop',
        }}
      >      
      <div className="down-arrow-container">
      <FaArrowDown size={30} color="brown" /> {/* Adjust the size and color as needed */}

      </div>

        
      </motion.div>
    </div>
  )
}

export default Timl
