import React from 'react'
import './Final.css'
const Final = () => {
  return (
    <div className="final-container">
        <h5>
    Thanks for checking out my site!      
    </h5>
    <br/>
        <h5>
            Click the message icon on the bottom right to get in touch.
        </h5>
    </div>
  )
}

export default Final