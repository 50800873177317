import React from 'react'

const LeftSide = () => {
  return (
    <div>
        <h3>
            DARIEN CHETRAM
        </h3>
    </div>
  )
}

export default LeftSide