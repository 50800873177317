import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Home from "../Home/Home";
import Tech from "../Tech/Tech";
import ProjectMain from "../Projects/ProjectMain";
import About from "../About/About";
import Timl from "../TIML/TimlMain";
import HeaterMain from "../Heater/HeaterMain";
import Meandmemain from "../MeandMe/Meandmemain";
import Contact from "../../components/Contact";
import TeflonMain from "../Teflon/TeflonMain";
import Final from "../Final/Final";
import Stack from "../Stack/Stack";

function Main() {
  const [scrollY, setScrollY] = useState(0); // Track scroll position
  const [scrollDirection, setScrollDirection] = useState(null); // 'up' or 'down'
  const [activeSection, setActiveSection] = useState(null); // Currently visible section

  const handleScroll = () => {
    const currentScroll = window.scrollY;

    // Determine scroll direction
    if (currentScroll > scrollY) {
      setScrollDirection("down");
    } else if (currentScroll < scrollY) {
      setScrollDirection("up");
    }
    setScrollY(currentScroll);

  };

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setActiveSection(entry.target.id); // Use `id` of the visible section
      }
    });
  };

  useEffect(() => {
    // Scroll event listener
    window.addEventListener("scroll", handleScroll);

    // IntersectionObserver to track visible sections
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Adjust visibility threshold as needed
    });

    // Observe all section elements
    document.querySelectorAll(".section").forEach((section) => observer.observe(section));
     
    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
      observer.disconnect();

    };
    
  }, [scrollY]);

  return (
    <div className="App">


      <motion.div
        id="home"
        className="section home"
        style={{
          backgroundPositionY: scrollY * 0.4,
          opacity: activeSection === "home" ? 1 : 0.5,
        }}
      >
        <Home />
      </motion.div>

      <motion.div
        id="about"
        className="section about"
        style={{
          backgroundPositionY: scrollY * 0.3,
          opacity: activeSection === "about" ? 1 : 0.5,
        }}
      >
        <About />
      </motion.div>

      {/* Repeat for other sections */}
      <motion.div id="tech" className="section tech" style={{ backgroundPositionY: scrollY * 0.3 }}>
        <Tech />
      </motion.div>
      <motion.div id="stack" className="section tech" style={{ backgroundPositionY: scrollY * 0.3 }}>
        <Stack />
      </motion.div>
      <motion.div id="projects" className="section tech" style={{ backgroundPositionY: scrollY * 0.3 }}>
        <ProjectMain />
      </motion.div>
      <motion.div id="timl" className="section tech" style={{ backgroundPositionY: scrollY * 0.3 }}>
        <Timl />
      </motion.div>
      <motion.div id="heater" className="section tech" style={{ backgroundPositionY: scrollY * 0.3 }}>
        <HeaterMain />
      </motion.div>
      <motion.div id="teflon" className="section tech" style={{ backgroundPositionY: scrollY * 0.3 }}>
        <TeflonMain />
      </motion.div>
      <motion.div id="meandme" className="section tech" style={{ backgroundPositionY: scrollY * 0.3 }}>
        <Meandmemain />
      </motion.div>
      <motion.div id="final" className="section tech" style={{ backgroundPositionY: scrollY * 0.3 }}>
        <Final />
      </motion.div>
      <Contact />
    </div>
  );
}

export default Main;
