import React from 'react'
import { FaArrowDown } from 'react-icons/fa'
import './HeaterMain.css'
import { motion, AnimatePresence } from 'framer-motion';
 import { useState, useEffect } from 'react';
const HeaterMain = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 528px)').matches);
    };

    checkMobile(); // Run the check on component mount
    window.addEventListener('resize', checkMobile); // Update on resize

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const heaterMainPath = isMobile
    ? { src: '/HeaterPictures/HeaterMobile.png' }
    : { src: '/HeaterPictures/HeaterFullCrop.png' };

  return (
    <div>
    <div className="LogoContainer">
    <a href="http://heater.darien-c.com" target="_blank" rel="noopener noreferrer">
  <img src={heaterMainPath.src} alt="timl Logo" />
</a>
    </div>
    <motion.div
        className="down-arrow-container"
        animate={{ y: [0, 10, 0] }} // Add up-down bounce animation
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatType: 'loop',
        }}
      >      
      <div className="down-arrow-container">
      <FaArrowDown size={30} color="black" /> {/* Adjust the size and color as needed */}

      </div>

        
      </motion.div>
    </div>
  )
}

export default HeaterMain