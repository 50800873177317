import React, { useState, useEffect } from 'react';
import { FaArrowDown } from 'react-icons/fa';
import './meandmemain.css';
import { motion } from "framer-motion"
const Meandmemain = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 528px)').matches);
    };

    checkMobile(); // Run the check on component mount
    window.addEventListener('resize', checkMobile); // Update on resize

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  const meandmelogo = isMobile
    ? { src: '/meandmePictures/meandmeMobile.png' }
    : { src: '/meandmePictures/MeandmeFullCrop.png' };

  return (
    <div>
      <div className="LogoContainer">
      <a href="http://meandme.darien-c.com" target="_blank" rel="noopener noreferrer">

        <img src={meandmelogo.src} alt="Me and Me Logo" />
        </a>
      </div>
      <motion.div
        className="down-arrow-container"
        animate={{ y: [0, 10, 0] }} // Add up-down bounce animation
        transition={{
          duration: 1,
          repeat: Infinity,
          repeatType: 'loop',
        }}
      >      
      <div className="down-arrow-container">
      <FaArrowDown size={30} color="black" /> {/* Adjust the size and color as needed */}

      </div>

        
      </motion.div>
    </div>
  );
};

export default Meandmemain;
