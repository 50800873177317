import React, {useState, useEffect} from 'react'
import './Stack.css'
import { motion } from 'framer-motion'
import { FaArrowDown } from 'react-icons/fa'
const Stack = () => {
    const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.matchMedia('(max-width: 528px)').matches);
    };

    checkMobile(); // Run the check on component mount
    window.addEventListener('resize', checkMobile); // Update on resize

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);
  return (
    <div className="stack-container">
      {isMobile ? (
        <div>
        <h5>
        I learned how to code from trial and error building projects I thought were cool. 
    </h5>
    <br/>
    <h5>
      I mostly use Javascript and Python for coding. My preferred web dev stack is React & Django, but I am comfortable with Express also. 
    </h5>
    <br/>
    <h5>You can download my resume below, which includes my full technical skillset.</h5>
    <br/>
  <a href="/resume.pdf" download>
    <button className="download-button" style={{background:"white"}}>Download</button>
  </a>
  <motion.div
    className="down-arrow-container-stack"
    animate={{ y: [0, 10, 0] }} // Add up-down bounce animation
    transition={{
      duration: 1,
      repeat: Infinity,
      repeatType: 'loop',
    }}
  >      
  <div className="down-arrow-container-stack">
  <FaArrowDown size={30} color="white" /> {/* Adjust the size and color as needed */}

  </div>

    
  </motion.div> 
    
        </div>
      ) : (
        <div>
        <h5>
        I learned how to code from trial and error building projects I thought were cool. 
    </h5>
    <br/>
    <h5>
        For Frontend development I prefer using JavaScript & React, as it's very straightforward.
    </h5>
    <br/>
    <h5>
        On the Backend I usually use Django as Python was the first language I learned, and it has a bunch of useful libraries.
    </h5>
    <br />
    <h5>
        I'm also comfortable with Express, as I use it right now in my current job at StageKeep.
    </h5>

    <br/>
    <h5>
        I have cloud computing experience as well, as I've hosted this site along with a number of projects using AWS and NGINX.
        </h5>
    <br/>
    <h5>You can download my resume below, which includes my full technical skillset.</h5>
    <br/>
  <a href="/resume.pdf" download>
    <button className="download-button" style={{background:"white"}}>Download</button>
  </a>
  <motion.div
    className="down-arrow-container-stack"
    animate={{ y: [0, 10, 0] }} // Add up-down bounce animation
    transition={{
      duration: 1,
      repeat: Infinity,
      repeatType: 'loop',
    }}
  >      
  <div className="down-arrow-container-stack">
  <FaArrowDown size={30} color="white" /> {/* Adjust the size and color as needed */}

  </div>

    
  </motion.div>
  </div>

      )}

    </div>
  )
}

export default Stack